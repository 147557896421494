/**
 * @file back-stage 模块接口
 */
import request from '@/utils/request';

/* ************************** 学校初始化 ************************** */
/**
 * @desc 获取初始化学校列表
 * @param { String } pageIndex 当前页码
 * @param { String } pageSize 每页显示多少记录
 * @param { String } schoolNameFuzzy 学校名称， 搜索框内容
 */
export const getInitSchoolPage = (data) => {
  return request({
    url: '/client/init/school/page/json/view.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 新增学校界面数据初始化（获取学段信息）
 */
export const getInitSchoolTypeList = (data) => {
  return request({
    url: '/client/init/school/add/json/view.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 获取配置学校服务器信息
 */
export const getInitSchoolServer = (data) => {
  return request({
    url: '/client/init/school/single/edit/json/view.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 编辑注册学校信息数据回显
 * @param { String } id 注册学校id
 */
export const editInitSchool = (data) => {
  return request({
    url: '/client/init/school/edit/json/view.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 保存注册学校信息
 * @param { String } id 注册学校id， 如果是编辑必填
 * @param { String } schoolKey 学校编码
 * @param { String } schoolName 学校名称
 * @param { String } schoolType 学段类型id
 * @param { String } adminName 学校管理者姓名
 * @param { String } adminPhone 学校管理者手机号码
 * @param { String } areaCode 所属地区编码
 * @param { String } areaPath 地区编码路径
 * @param { String } areaName 所属地区
 */
export const saveInitSchool = (data) => {
  return request({
    url: '/client/init/school/save.do',
    method: 'post',
    data,
  });
};
/**
 * @desc 保存服务器初始化配置
 * @param { String } schoolKey 学校编码
 * @param { String } host 服务器IP
 * @param { Number } port 端口号
 * @param { String } adminName 管理员名称
 * @param { String } adminPhone 管理员手机号
 * @param { String } hostUrl 服务器访问地址(作废)
 * @param { String } schoolName 学校名称(作废)
 * @param { Number } schoolType 学校类型(作废)
 */
export const saveInitServer = (data) => {
  return request({
    url: '/client/init/school/single/save.do',
    method: 'post',
    data,
  });
};
/**
 * @desc 保存初始化教育局配置
 */
export const saveInitEducation = (data) => {
  return request({
    url: '/client/init/education/save.do',
    method: 'post',
    data,
  });
};
/**
 * @desc 重置学校管理者密码
 * @param { String } userId 用户id
 */
export const resetUserPassword = (data) => {
  return request({
    url: '/user/resetPassword.do',
    method: 'POST',
    data,
  });
};

/* ************************** 全通单点登录配置 ************************** */
/**
 * @desc 学校名称搜索注册学校信息
 * @param { String } schoolNameFuzzy 学校名称， 搜索框内容
 */
export const getRegisterSchoolList = (data) => {
  return request({
    url: '/server/school/register/search/list/json.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 全通单点登录配置列表分页
 * @param { Number } pageIndex 页码
 * @param { Number } pageSize 页面容量
 * @param { String } schoolNameFuzzy 关键字
 */
export const getQuantongPage = (data) => {
  return request({
    url: '/quantong/page/json.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 保存全通单点登录配置信息
 * @param { String } id 全通配置id，编辑的时候必填
 * @param { String } thirdPartySchoolId 第三方学校id，即为：全通学校id
 * @param { String } schoolKey 新增关联学校编码
 */
export const saveQuantong = (data) => {
  return request({
    url: '/quantong/save.do',
    method: 'post',
    data,
  });
};
/**
 * @desc 删除全通配置关联记录
 * @param { String } id 全通单点登录配置记录id
 */
export const removeQuantong = (data) => {
  return request({
    url: '/quantong/remove.do',
    method: 'post',
    data,
  });
};
/**
 * @desc 全通平台：用户验证
 * @param { String } ticket 票据（从跳转参数中获取）
 * @param { String } domain 项目域名（从跳转参数中获取）
 */
export const quantongValida = (data) => {
  return request({
    url: '/quantong/valida.do',
    method: 'post',
    data,
  });
};
/**
 * @desc 全通平台：登录跳转
 * @param { String } userId 用户id
 * @param { String } mobileNo 手机号码
 * @param { String } encryption 加密串
 */
export const quantongLogin = (data) => {
  return request({
    url: '/quantong/json/redirect.do',
    method: 'post',
    data,
  });
};

/* ************************** 全连接平台接入配置 ************************** */
/**
 * @desc 全连接配置信息分页查询
 * @param { String } pageIndex 当前页码
 * @param { String } pageSize 每页显示记录数
 * @param { String } platFormAreaName 平台名称，搜索框内容
 * @param { String } projectCode 项目编码，暂时没找到哪里输入这个值...
 */
export const getAllConnectPage = (data) => {
  return request({
    url: '/schoolManager/accessConfiguration/pageConfiguration.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 新增、编辑保存全连接配置信息
 * @param { String } level 所属地区级别，0：国家级，1：省级，2：市级，3：县区级
 * @param { String } areaPlatFormName 区域全连接平台名称
 * @param { String } areaPlatFormUrl 区域全连接平台地址
 * @param { String } appKey appKey
 * @param { String } appId appId
 * @param { String } id 配置记录id，编辑的时候必填
 * @param { String } countryCode 国家编码，中国
 * @param { String } provinceCode 省份编码，广东省，级别为1的时候需要填写
 * @param { String } cityCode 城市编码，清远市，级别为2的时候需要填写
 * @param { String } areaCode 县区编码，清城区，级别为3的时候需要填写
 */
export const saveAllConnectConfig = (data) => {
  return request({
    url: '/schoolManager/accessConfiguration/saveOrUpdateConfiguration.do',
    method: 'post',
    data,
  });
};
/**
 * @desc 删除全连接关联配置记录
 * @param { String } platFormConfigId 配置记录id
 */
export const deleteAllConnectConfig = (data) => {
  return request({
    url: '/schoolManager/accessConfiguration/deleteConfiguration.do',
    method: 'post',
    data,
  });
};
/**
 * @desc 查询地区区域信息
 * @param { String } parentAreaCode 父级区域编码，若为空，则为获取国家列表
 */
export const getAreaList = (data) => {
  return request({
    url: '/schoolManager/accessConfiguration/listArea.do',
    // url: 'http://rap2.qljy.com:7777/app/mock/data/3311',
    method: 'get',
    data,
  });
};
/**
 * @desc 配置学校关联信息 根据三方密钥获取需要同步的学校列表（已选）
 * @param { String } appKey appKey
 */
export const getSelectedThirdSchoolByAppKey = (data) => {
  return request({
    url: '/schoolManager/accessConfiguration/listThirdSchoolByAppKey.do',
    // url: 'http://rap2.qljy.com:7777/app/mock/data/3335',
    method: 'get',
    data,
  });
};
/**
 * @desc 配置学校关联信息 根据三方应用密钥查询学校
 * @param { String } appKey appKey
 */
export const getThirdSchoolByAppKey = (data) => {
  return request({
    url: '/schoolManager/accessConfiguration/searchThirdSchoolByAppKey.do',
    // url: 'http://rap2.qljy.com:7777/app/mock/data/3336',
    method: 'get',
    data,
  });
};
/**
 * @desc 配置学校关联信息 保存需要同步的三方学校列表
 * @param { String } syncThirdSchools 第三方学校信息，包含学校名称，学校区域名称，学校id
 * @param { String } appKey appKey
 */
export const saveSyncThirdSchools = (data) => {
  return request({
    url: '/schoolManager/accessConfiguration/saveSyncThirdSchools.do',
    method: 'post',
    data,
  });
};

/* ******************************** 第三方学校帐号同步 *********************************/
/**
 * @desc 查询平台列表
 */
export const getThirdProjectList = (data) => {
  return request({
    url: '/schoolManager/thirdProjectList.do',
    method: 'get',
    data,
  });
};

/**
 * @desc 查询区域列表
 * @param { String } projectCode 平台编码
 */
export const getThirdPlatformList = (data) => {
  return request({
    url: '/schoolManager/thirdPlatformList.do',
    method: 'get',
    data,
  });
};

/**
 * @desc 查询配置同步的学校列表
 * @param { String } projectCode 平台编码
 * @param { String } areaCode 地区编码
 * @param { Number } pageIndex 当前页码
 * @param { Number } pageSize 每页显示记录数
 */
export const getThirdPartSyncList = (data) => {
  return request({
    url: '/schoolManager/thirdPartSync/pageList.do',
    method: 'get',
    data,
  });
};

/**
 * @desc 删除配置同步的学校
 * @param { String } id 学校id
 */
export const deleteThirdPartSync = (data) => {
  return request({
    url: '/schoolManager/thirdPartSync/delete.do',
    method: 'post',
    data,
  });
};

/**
 * @desc 已配置的第三方学校列表
 * @param { String } appKey 应用密钥
 * @param { String } projectCode 平台编码
 * @param { Number } syncType 同步方式 1=新增 2=匹配
 */
export const getThirdPartConfigSchoolList = (data) => {
  return request({
    url: '/schoolManager/thirdPartSync/configSchoolList.do',
    method: 'get',
    data,
  });
};

/**
 * @desc (搜索可)选择学校列表
 * @param { String } appKey 应用密钥
 * @param { String } projectCode 平台编码
 * @param { String } platformCode 区域编码
 * @param { String } schoolName 学校名称（搜索关键字）
 */
export const searchThirdPartSchoolList = (data) => {
  return request({
    url: '/schoolManager/thirdPartSync/schoolList.do',
    method: 'get',
    data,
  });
};

/**
 * @desc 保存配置同步的学校
 * @param { String } appKey 平台的密钥
 * @param { String } qlAppkey 青鹿密钥
 * @param { String } projectCode 平台编码
 * @param { String } projectName 平台名称
 * @param { String } platformCode 区域编码
 * @param { String } platformName 区域名称
 * @param { Number } syncType 同步方式 1=新增 2=匹配
 * @param { String } syncThirdSchools 学校列表json数组，
 * status: 0 去掉原先已选的
 * status: 1 新选的，或者保留原来已选的
 * tips: 或者只传新选的，旧的也不会被删掉，涉及此接口的修改，最好去和后台再次进行沟通
 * syncThirdSchools 格式：
 *  [
 *    {"areaName":"","schoolId":"","schoolName":"","status":0},
 *    {"areaName":"","schoolId":"","schoolName":"","status":1}
 *  ]
 */
export const saveThirdPartSync = (data) => {
  return request({
    url: '/schoolManager/thirdPartSync/save.do',
    method: 'post',
    data,
  });
};

/* ******************************** 权限管理 *********************************/
/**
 * @desc 查询包含权限的注册学校列表数据
 * @param { String } pageIndex 当前页码
 * @param { String } pageSize 每页显示记录数
 * @param { String } schoolNameFuzzy 学校名称， 搜索框的内容
 */
export const getSchoolAuthorityPage = (data) => {
  return request({
    url: '/register/school/pageAuthority/json.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 编辑学校权限，数据回显
 * @param { String } id 学校id，传入的是schoolId
 */
export const getSchoolAuthorityDetail = (data) => {
  return request({
    url: '/register/school/pageAuthority/edit/json/view.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 保存学校权限信息
 * @param { String } schoolToThirdResources 第三方资源服务器list对象的json字符串，包含服务id，服务名称
 * @param { String } schoolToThirdApplications 第三方应用服务器list对象的json字符串，包含服务id，服务名称
 * @param { String } schoolId 学校id
 */
export const saveResourceAuthority = (data) => {
  return request({
    url: '/register/school/saveResourceAuthority.do',
    method: 'post',
    data,
  });
};

/* ************************** 学校数据推送配置 ************************** */
/**
 * @desc 分页查询学校数据推送配置
 * @param { String } pageIndex 当前页码
 * @param { String } pageSize 每页显示记录数
 * @param { String } schoolNameFuzzy 平台名称， 搜索框内容
 */
export const getDataPushSchoolPage = (data) => {
  return request({
    url: '/schoolManager/accessConfiguration/pageSchoolConfig.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 根据输入的学校名称查询学校列表
 * @param { String } schoolName 学校名称，用于模糊搜索
 * @param { Number } appKey 区域平台appkey，查询全部时候就不传
 */
export const getDataPushSchoolByName = (data) => {
  return request({
    url: '/schoolManager/accessConfiguration/getSchoolList.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 获取全连接区域平台列表
 * @param { String } platFormAreaName 区域平台名称
 */
export const getAllConnectAreaList = (data) => {
  return request({
    url: '/schoolManager/accessConfiguration/configurationList.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 删除关联信息
 * @param { String } schoolConfigId 推送配置记录id
 */
export const deleteSchoolConfig = (data) => {
  return request({
    url: '/schoolManager/accessConfiguration/deleteSchoolConfig.do',
    method: 'post',
    data,
  });
};
/**
 * @desc 新增、编辑保存配置信息
 * @param { String } platformName 推送平台名称
 * @param { String } platformUrl 推送平台地址
 * @param { String } schoolConfigSchool 选择的学校信息，包含学校id、学校名称，当前选中状态。  对于新增新选择的学校，status 为1 ，对于删除已选择的学校，status 为0。
 * @param { String } id 配置信息id
 */
export const saveOrUpdateSchoolConfig = (data) => {
  return request({
    url: '/schoolManager/accessConfiguration/saveOrUpdateSchoolConfig.do',
    method: 'post',
    data,
  });
};

/* ************************** 安徽监管平台接入配置 ***************************/
/**
 * @desc 安徽监管平台接入配置 分页查询学校项目列表
 * @param { String } keyword 搜索关键词，可模糊搜索学校、项目名称
 * @param { Number } pageIndex 页码，默认1
 * @param { Number } pageSize 页大小，默认10
 */
export const getAnhuiSchoolProjectPage = (data) => {
  return request({
    url: '/anhuimanage/getSchoolProjectPage.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 安徽监管平台接入配置 根据学校名称查询三方学校列表
 * @param { String } schoolName 学校名称，输入框的内容
 */
export const getAnhuiSchoolByName = (data) => {
  return request({
    url: '/anhuimanage/getSchoolList.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 安徽监管平台接入配置 查询关联的学校列表
 * @param { String } projectCode 项目编号
 */
export const getAnhuiSchoolByProjectCode = (data) => {
  return request({
    url: '/anhuimanage/getSchoolListByProjectCode.do',
    method: 'get',
    data,
  });
};
/**
 * @desc 安徽监管平台接入配置 新增或编辑学校项目关联
 * @param { String } schoolArrStr 学校列表字符串，格式[{"schoolId":"学校id","schoolName":"学校名称"}]，需要Encode
 * @param { String } projectName 项目名称
 * @param { String } projectCode 项目编号
 * @param { String } id id，编辑的时候，必填
 */
export const saveAnhuiSchoolProject = (data) => {
  return request({
    url: '/anhuimanage/saveSchoolProject.do',
    method: 'post',
    data,
  });
};

/** 注册教育局 */
/**
 * @desc 分页查询教育局信息
 * @param { String } pageIndex 当前页码
 * @param { String } pageSize 每页显示记录数量
 * @param { String } educationNameFuzzy 教育局名称
 */
export const getRegisterEducation = (data) => {
  return request({
    url: '/register/education/page/json.do',
    method: 'GET',
    data,
  });
};

/**
 * @desc 编辑新增之后，保存教育局信息
 * @param { String } id id，编辑的时候，必填
 * @param { String } educationKey 教育局编码，新增的时候必填，编辑的时候禁止修改，所以不用传。
 * @param { String } educationName 教育局名称，必填
 */
export const saveRegisterEducation = (data) => {
  return request({
    url: '/register/education/save.do',
    method: 'POST',
    data,
  });
};

/** 注册学校 */
/**
 * @desc 分页查询注册学校信息
 * @param { String } pageIndex 当前页码
 * @param { String } pageSize 每页显示的记录数
 * @param { String } schoolNameFuzzy 学校名称， 搜索框的内容
 */
export const getRegisterSchool = (data) => {
  return request({
    url: '/register/school/page/json.do',
    method: 'GET',
    data,
  });
};
/**
 * @desc 新增注册学校，数据初始化
 */
export const getRegisterSchoolAdd = (data) => {
  return request({
    url: '/register/school/add/json/view.do',
    method: 'GET',
    data,
  });
};
/**
 * @desc 新增和编辑后， 保存学校信息
 * @param { String } schoolKey 学校编码
 * @param { String } schoolName 学校名称
 * @param { String } schoolType 学校学段类型id
 * @param { String } architectId 服务器类型id
 * @param { String } serverName 所属教育局编码，qlcloud：学校云，""：学校自购
 * @param { String } validateTime 有效期
 * @param { String } areaCode 所属地区编码
 * @param { String } areaPath 所属地区编码路径
 * @param { String } areaName 所属地区
 * @param { String } id id, 编辑的时候必填
 */
export const saveRegisterSchool = (data) => {
  return request({
    url: '/register/school/save.do',
    method: 'POST',
    data,
  });
};
/**
 * @desc 编辑注册学校信息，数据回显
 * @param { String } id 注册学校id
 */
export const getRegisterSchoolEdit = (data) => {
  return request({
    url: '/register/school/edit/json/view.do',
    method: 'GET',
    data,
  });
};
/** 远程升级 */
/**
 * @desc 远程升级界面查询注册学校列表
 * @param { String } pageIndex 当前页码
 * @param { String } pageSize 每页显示的记录数量
 * @param { String } schoolNameFuzzy 学校名称，搜索框的内容
 * @param { String } isLocal 是否本地服务器，0：本地服务器， 1：公共服务器
 */
export const getListRegisterSchool = (data) => {
  return request({
    url: '/server/registerSchool/listRegisterSchool.do',
    method: 'GET',
    data,
  });
};
/**
 * @desc 根据首字母分组查询学校列表
 * @param { String } schoolNameFuzzy 学校名称，搜索框的内容
 * @param { String } isLocal 是否公共服务器， 1：本地服务器，0：公共服务器
 * @param { String } schoolVersionCode 学校版本号，pc端暂时没有看到又传这个参数
 */
export const getListRegisterSchoolGroup = (data) => {
  return request({
    url: '/server/registerSchool/listRegisterSchoolGroup.do',
    method: 'GET',
    data,
  });
};
/**
 * @desc 通过学校查询盒子,并关联出版本信息和更新状态信息
 * @param { String } schoolKey 学校编码
 * @param { String } schoolVersionCode 选中的学校版本号
 */
export const getListRegisterClassBySchoolKey = (data) => {
  return request({
    url: '/server/registerClass/listRegisterClassBySchoolKey.do',
    method: 'GET',
    data,
  });
};
/**
 * @desc 查询学校兼容的盒子版本号
 * @param { String } schoolKey 学校编码(批量升级时不传,单个学校升级时必填,目的是为了查询该学校下面的盒子)
 * @param { String } schoolVersionCode 学校版本号
 * 1.公共服务器升级时,传云平台的版本号<br>
 * 2.本地服务器批量升级时,如果在升级后台时选择不升级,则传被勾选的学校的最高版本号;如果勾选了后台升级的版本号,则将该版本号与被勾选的学校的最高版本号进行比较取最大值<br>
 * 3.本地服务器单个学校升级时,如果在升级后台时选择不升级,该参数可以不传(后台会默认取该学校的版本号),如果勾选了后台升级的版本号,则传勾选的版本号<br>
 * @param { String } classVersionCode 被勾选的盒子的最高版本号(必填)
 */
export const getRegisterClassListVersions = (data) => {
  return request({
    url: '/server/registerClass/listVersions.do',
    method: 'GET',
    data,
  });
};
/**
 * @desc 查询云平台兼容的学校版本号
 * @param { String } schoolKey 学校编码
 */
export const getRegisterSchoolListVersions = (data) => {
  return request({
    url: '/server/registerSchool/listVersions.do',
    method: 'GET',
    data,
  });
};
/**
 * @desc 逻辑删除注册盒子
 * @param { String } serverName 服务名称,服务器唯一标识
 */
export const removeRegisterClass = (data) => {
  return request({
    url: '/server/registerClass/remove.do',
    method: 'POST',
    data,
  });
};
/**
 * @desc 批量升级学校中的盒子
 * @param { String } schoolDownloadUrl 学校安装包下载地址(如果存在学校安装包下载地址,则为本地服务器升级)
 * @param { String } schoolVersionCode 学校安装包对应的版本号
 * @param { String } schoolKeys 学校编码,多个以","分割
 * @param { String } classDownloadUrl 盒子安装包下载地址
 * @param { String } classVersionCode 盒子安装包对应的版本号
 * @param { String } compelUpdateTime 强制更新时间(格式:yyyy-MM-dd HH:mm)
 */
export const saveBatchUpgrateRegisterClass = (data) => {
  return request({
    url: '/server/registerClass/saveBatchUpgrate.do',
    method: 'POST',
    data,
  });
};

/* ************************** 删除点阵笔 ************************** */
/**
 * @desc 查询点阵笔是否可使用
 * @param { String } number 笔编号
 */
export const selectPenByNumber = (data) => {
  return request({
    url: '/dianzhenbi/selectPenByNumber.do',
    method: 'get',
    data,
  });
};

/**
 * @desc 删除所有绑定的点阵笔关系
 * @param { String } number 笔编号
 */
export const deleteAllByNumber = (data) => {
  return request({
    url: '/dianzhenbi/deleteAllByNumber.do',
    method: 'get',
    data,
  });
};
